import { ErrorPopupProvider } from '@integration-app/console/components/Error/ErrorPopup'
import * as Sentry from '@sentry/react'
import '../styles/globals.css'
import '@integration-app/react/styles.css'
import '../styles/overrides.css'
import Head from 'next/head'

if (process.env.NEXT_PUBLIC_ENABLE_TRACKING) {
  Sentry.init({
    dsn: 'https://71439af05abc42d984c6d8ead38de3a8@o956397.ingest.sentry.io/5905799',
  })
}

export default function IntegrationUI({ Component, pageProps }) {
  return (
    <ErrorPopupProvider>
      <Head>
        <title>Integration App UI</title>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
      </Head>

      <Component {...pageProps} />
    </ErrorPopupProvider>
  )
}
