// Putting these env variables into an object prevents builder from optimizing them out because they are constants.
// This is necessary to change these variables at runtime via entrypoint.sh.
export const ENV_CONFIG = {
  LOCAL_ENVIRONMENT:
    !!process?.env?.NEXT_PUBLIC_ENGINE_API_URI?.startsWith('http://localhost'),
  BASE_URI: process.env.NEXT_PUBLIC_BASE_URI,
  ENGINE_API_URI: process.env.NEXT_PUBLIC_ENGINE_API_URI,
  ENGINE_UI_URI: process.env.NEXT_PUBLIC_ENGINE_UI_URI,
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  AUTH0_CLIENT_ID_PROD: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_PROD,
  ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  ENABLE_TRACKING: process.env.NEXT_PUBLIC_ENABLE_TRACKING,
  USE_CONNECTOR_STORE: process.env.NEXT_PUBLIC_USE_CONNECTOR_STORE,
  PYLON_APP_ID: process.env.NEXT_PUBLIC_PYLON_APP_ID,
  ENABLE_LIMITS: process.env.NEXT_PUBLIC_ENABLE_LIMITS,
  POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  NODE_ENV: process.env.NODE_ENV,
  ENABLE_USAGE_ALERTS: process.env.NEXT_PUBLIC_ENABLE_USAGE_ALERTS === 'true',
  ENABLE_BILLING: process.env.NEXT_PUBLIC_ENABLE_BILLING,
}
