import { createContext, useContext, useEffect } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { Router, useRouter } from 'next/router'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import useSWR, { KeyedMutator } from 'swr'

import { ENV_CONFIG } from 'config/env'
import { pushGtmEvent } from 'hooks/gtm'
import useApi from 'hooks/useApi'
import { IS_LOCAL } from 'routes/Workspaces/Workspace/routes/connectors/components/config'

const REDIRECT_AFTER_LOGIN_PATH_KEY = 'redirectAfterLoginPath'

type AuthContextType = {
  self: any
  mutateSelf: KeyedMutator<any>
  loading: boolean
  refresh: () => void
  login: (returnTo?: string) => Promise<void>
  loginProd: (returnTo?: string) => Promise<void>
  logout: () => Promise<void>
  loginLink: (returnTo?: string) => string
}

const AuthContext = createContext<AuthContextType>({
  self: null,
  mutateSelf: () => Promise.resolve(),
  loading: true,
  refresh: () => null,
  login: async () => undefined,
  loginProd: async () => undefined,
  logout: async () => undefined,
  loginLink: () => '',
})

let handlingLoginCallback = false

export const AuthProvider = ({ children }) => {
  const {
    apiFetcher,
    setToken,
    unsetToken,
    getImpersonationToken,
    unsetImpersonationToken,
  } = useApi()

  const workspaceId = useRouter().query.workspaceId

  const {
    data: self,
    isLoading,
    error,
    mutate,
  } = useSWR(
    workspaceId ? `console-self?workspaceId=${workspaceId}` : 'console-self',
    apiFetcher,
  )

  const allowTracking = ENV_CONFIG.ENABLE_TRACKING && !getImpersonationToken()
  const userLoggedIn = !!self?.user?.id && !!self?.user?.email
  const userLoggedOut = error?.status === 401

  useEffect(() => {
    if (
      allowTracking &&
      userLoggedIn &&
      !self.user?.email.includes('@integration.app')
    ) {
      posthog.init(ENV_CONFIG.POSTHOG_KEY ?? '', {
        api_host: ENV_CONFIG.POSTHOG_HOST || 'https://eu.i.posthog.com',
        // Enable debug mode in development
        loaded: (posthog) => {
          posthog.identify(self?.user?.email, {
            displayName: self?.user?.email,
            email: self?.user?.email,
            orgId: self.workspace?.orgId,
            defaultWorkspaceId: self?.user?.defaultWorkspaceId,
            isTrial: !!self.orgs.find((org) => org.id === self.workspace?.orgId)
              ?.trialEndDate,
          })
          posthog.group('organization', self.workspace?.orgId)
          if (ENV_CONFIG.NODE_ENV === 'development') {
            posthog.debug()
          }

          posthog.onSessionId((sessionId) => {
            posthog.capture('new_session', {
              sessionURL: posthog.get_session_replay_url({
                withTimestamp: true,
              }),
              idleSessionId: sessionId,
            })
          })
        },
      })
      const handleRouteChange = () => {
        posthog?.capture('$pageview')
      }
      Router.events.on('routeChangeComplete', handleRouteChange)

      return () => {
        Router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
    return
  }, [allowTracking, userLoggedIn, self?.user?.id])

  useEffect(() => {
    if (allowTracking && userLoggedIn) {
      pushGtmEvent('user_identify', {
        userEmail: self.user.email,
        userName: self.user?.name,
      })
    }

    if (
      allowTracking &&
      userLoggedIn &&
      ENV_CONFIG.PYLON_APP_ID &&
      !self.user?.email.includes('@integration.app')
    ) {
      // Suppressed type error that appear only in engine-ui
      // @ts-ignore
      window.pylon = {
        chat_settings: {
          app_id: ENV_CONFIG.PYLON_APP_ID,
          email: self?.user?.email,
          name: self?.user?.name || self?.user?.email,
        },
      }

      const loaderScript = document.createElement('script')
      loaderScript.type = 'text/javascript'
      loaderScript.async = true
      loaderScript.src = `https://widget.usepylon.com/widget/${ENV_CONFIG.PYLON_APP_ID}`

      document.body.appendChild(loaderScript)
    }
  }, [allowTracking, userLoggedIn, self?.user?.email])

  async function createAuthClient() {
    const domain = ENV_CONFIG.AUTH0_DOMAIN
    const clientId = ENV_CONFIG.AUTH0_CLIENT_ID

    if (!domain || !clientId) {
      throw new Error(
        'Environment variables NEXT_PUBLIC_AUTH0_DOMAIN and NEXT_PUBLIC_AUTH0_CLIENT_ID must be defined',
      )
    }

    return await createAuth0Client({
      domain: domain,
      client_id: clientId,
    })
  }

  async function createAuthProdClient(redirectUri?: string) {
    const domain = ENV_CONFIG.AUTH0_DOMAIN
    const clientId = ENV_CONFIG.AUTH0_CLIENT_ID_PROD

    if (!domain || !clientId) {
      throw new Error(
        'Environment variables NEXT_PUBLIC_AUTH0_DOMAIN and NEXT_PUBLIC_AUTH0_CLIENT_ID_PROD must be defined',
      )
    }

    return await createAuth0Client({
      domain: domain,
      client_id: clientId,
      redirect_uri: redirectUri,
    })
  }

  async function login(returnTo?: string) {
    if (IS_LOCAL) {
      localStorage.setItem('loginApp', 'dev')
    }
    const client = await createAuthClient()
    localStorage.setItem(
      REDIRECT_AFTER_LOGIN_PATH_KEY,
      returnTo ?? window.location.pathname,
    )
    await client.loginWithRedirect({
      redirect_uri: window.location.origin,
    })
  }

  async function loginProd(returnTo?: string) {
    const prodClient = await createAuthProdClient(window.location.origin)
    localStorage.setItem(
      REDIRECT_AFTER_LOGIN_PATH_KEY,
      returnTo ?? window.location.pathname,
    )
    if (IS_LOCAL) {
      localStorage.setItem('loginApp', 'prod')
    }
    await prodClient.loginWithRedirect({
      // Optionally include any additional parameters required for the second app's login
    })
  }

  function refresh() {
    void mutate()
  }

  async function logout() {
    unsetToken()
    unsetImpersonationToken()
    const client = await createAuthClient()
    await client.logout({
      returnTo: window.location.origin,
    })
  }

  async function handleLoginCallback() {
    let isProdLogin: boolean = false
    if (IS_LOCAL) {
      isProdLogin = localStorage.getItem('loginApp') === 'prod'
    }
    const client = isProdLogin
      ? await createAuthProdClient()
      : await createAuthClient()
    // Check if the URL contains the code and state parameters
    const urlParams = new URLSearchParams(window.location.search)
    if (!urlParams.has('code') || !urlParams.has('state')) {
      throw new Error('Invalid callback URL')
    }

    // Process the login state

    await client.handleRedirectCallback()

    const claims = await client.getIdTokenClaims()
    if (claims) {
      if (isProdLogin) {
        localStorage.setItem('prodToken', claims.__raw)
      } else {
        unsetImpersonationToken()
        setToken(claims.__raw)
      }

      let redirectAfterLoginPath = localStorage.getItem(
        REDIRECT_AFTER_LOGIN_PATH_KEY,
      )
      // Don't allow absolute URLs to be used as redirect paths
      // it's a security risk
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      if (redirectAfterLoginPath.includes('://')) {
        localStorage.removeItem(REDIRECT_AFTER_LOGIN_PATH_KEY)
        redirectAfterLoginPath = null
      }
      if (
        redirectAfterLoginPath &&
        !redirectAfterLoginPath.includes('/login')
      ) {
        localStorage.removeItem(REDIRECT_AFTER_LOGIN_PATH_KEY)
        window.location.href = redirectAfterLoginPath
      } else {
        window.location.href = window.location.origin
      }
    }
  }

  if (typeof window !== 'undefined') {
    const query = window.location.search
    if (query.includes('code=')) {
      if (!handlingLoginCallback) {
        handlingLoginCallback = true
        void handleLoginCallback()
      }
      return null
    }
  }

  function loginLink(returnTo?: string) {
    return (
      `/login?` + (returnTo ? `returnTo=${encodeURIComponent(returnTo)}` : '')
    )
  }

  if (error && !userLoggedOut) {
    return <div>Error: {error.message}</div>
  }

  return (
    <AuthContext.Provider
      value={{
        self,
        mutateSelf: mutate,
        loading: isLoading,
        login,
        logout,
        loginProd,
        refresh,
        loginLink,
      }}
    >
      <PostHogProvider client={posthog}>{children}</PostHogProvider>
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
